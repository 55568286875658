@import 'src/index.scss';

.tabsWrapper {
  display: flex;
  justify-content: center;
  margin: 1.25rem 0;

  @include breakpoint(sm) {
    justify-content: flex-start;
    margin: 1rem 0 0.75rem 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.tabsRoot {
  min-height: auto;
}

.tabsIndicator {
  display: none;
}

.tabRoot {
  font: var(--font-menu-desktop);
  color: var(--color-primary);
  text-transform: none;
  text-decoration: none;
  padding: 0 2.8125rem 0 1.3125rem;
  outline: 0;
  margin: 0;
  min-width: auto;
  overflow: visible;
  letter-spacing: inherit;
  min-height: auto;

  @include breakpoint(sm) {
    font: var(--font-menu-mobile);
    padding: 0 1rem 0 1.3125rem;
  }

}

.tabActive {
  color: var(--color-accent) !important;
  transition: color 300ms ease-in-out;

  &::before {
    content: '';
    position: absolute;
    width: 1rem;
    border-top: 1px solid var(--color-primary);
    border-bottom: 1px solid var(--color-primary);
    top: 50%;
    left: 0;
  }
}
