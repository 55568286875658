@import 'src/index.scss';

.container {
  width: calc(33.333% - 0.75rem);
  margin-bottom: 1rem;

  @include breakpoint(md) {
    width: calc(50% - 0.5rem);
  }

  @include breakpoint(sm) {
    width: 100%;
    margin-bottom: 0;
  }
}

.wallPost {
  position:relative;
  box-sizing: border-box;
  height: 100%;
  overflow:hidden;
  box-shadow: 0px 3px 6px #00000029;

  @include breakpoint(sm) {
    height: calc(100% - 4.5rem);
  }

  p {
    font: var(--font-body-medium);
    white-space: pre-line;
    word-wrap: break-word;
    padding: 1rem;

    a {
      transition: color 100ms;

      &:hover {
        color: var(--color-accent);
        transition: color 200ms;
      }
    }
  }

  &:hover .arrow {
    opacity: 1;
    transition: opacity ease-in-out 300ms;
  }
}

.after {
  @include breakpoint(sm) {
    width: 100%;
    height: 2.5rem;
    margin: 1rem 0;
    background: center no-repeat url('../../../../img/post.svg');
  }
}

.wallImg {
  display: block;
  width: 100%;
  height: 35vh;
  object-fit: cover;
  object-position: center;

  @include breakpoint(lg) {
    height: 35vh;
  }

  @include breakpoint(md) {
    height: 45vh;
  }

  @include breakpoint(sm) {
    width: 100%;
    height: 65vh;
  }

  @include breakpoint(xs) {
    height: 55vh;
  }
}

.slider {
  height: 35vh;
  position: relative;

  @include breakpoint(lg) {
    height: 35vh;
  }

  @include breakpoint(md) {
    height: 45vh;
  }

  @include breakpoint(sm) {
    width: 100%;
    height: 65vh;
  }

  @include breakpoint(xs) {
    height: 55vh;
  }
}

.bullets {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 1rem 0;
}

.bullet {
  display: block;
  width: 1.5rem;
  height: 0.125rem;
  background-color: #ffffff;
  margin: 0 0.1563rem;
}

.active {
  background-color: #4D4D4D;
}

.arrows {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-between;
  position: absolute;
  top: 48%;
  padding: 0 1.5rem;
}

.arrow {
  border-bottom: 2px solid #f0efeb;
  border-left: 2px solid #f0efeb;
  padding: 1rem;
  opacity: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;

  @include breakpoint(sm) {
    display: none;
  }
}

.arrowLeft {
  transform: rotate(45deg);
}

.arrowRight {
  transform: rotate(225deg);
}

.disabled {
  visibility: hidden;
  cursor: default;
}
