$breakpoints: (
  xs: 575.98px,
  sm: 767.98px,
  md: 991.98px,
  lg: 1199.98px,
);

@mixin breakpoint($breakpoint) {

  $breakpoint-value: map-get($breakpoints, $breakpoint);
  
  @media (max-width: ($breakpoint-value)) {
    @content;
  }
}
