@import 'src/index.scss';

.porfolio {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.item {
  width: calc(33.333% - 0.75rem);
  margin-bottom: 15px;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px #00000029;

  @include breakpoint(md) {
    width: calc(50% - 0.5rem);
  }

  @include breakpoint(sm) {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.item img {
  width: 100%;
  height: 40vh;
  display: block;
  object-fit:cover;
  object-position: center;

  @include breakpoint(lg) {
    height: 45vh;
  }

  @include breakpoint(md) {
    height: 45vh;
  }

  @include breakpoint(sm) {
    border-radius: 0;
    height: 65vh;
  }

  @include breakpoint(xs) {
    height: 55vh;
  }
}
