@import 'src/index.scss';

.app {
  height: 100%;
}

.container{
  min-height: 100%;
  display: flex;
  flex-direction:column;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 0 1rem;

  @include breakpoint(lg) {
    max-width: 62rem;
  }

  @include breakpoint(md) {
    max-width: 54.25rem;
  }

  @include breakpoint(sm) {
    padding: 0;
  }

  @include breakpoint(xs) {
    min-width: 19.9375rem;
  }
}

.content {
  flex: 1 0 auto;
  margin-top: 10.25rem;

  @include breakpoint(sm) {
    margin-top: 8rem;
  }
}

