@import 'src/index.scss';

.appBarRoot {
  background-color: var(--background-color-default);
  box-shadow: none;
}

.title {
  font: var(--font-title-desktop);
  text-align: center;
  margin-top: 1.25rem;
  cursor: pointer;

  span {
    font: inherit;
    color: var(--color-accent);
    transition: color 500ms;
  }

  @include breakpoint(sm) {
    font: var(--font-title-mobile);
    padding-left: 1rem;
    text-align: left;
    margin-top: 0.75rem;
  }
}
